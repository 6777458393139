import "./nutribot.css"
import React, { useEffect, useRef } from "react";
function Nutribot() {
      const backgroundRef = useRef(null);
  useEffect(() => {
        // Check if the user is on a mobile device (iPhone, iPad, Android)
        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
          // Add scroll event listener to simulate the fixed background effect
          const handleScroll = () => {
            const scrollPosition = window.pageYOffset;
            backgroundRef.current.style.backgroundPosition = `center ${scrollPosition * 0.5}px`;
          };
    
          window.addEventListener('scroll', handleScroll);
    
          // Cleanup the event listener when the component is unmounted
          return () => {
            window.removeEventListener('scroll', handleScroll);
          };
        }
      }, []); // Empty dependency array means this effect runs once, when the component mounts
       
         
     return (
          <div className="home">
               <div ref={backgroundRef} className="intro2">
               
                    <div className="teaser">
                         Three simple steps to success
                         <br></br>
                         
                         <img src="/img/nutribot.png" className="cover2" alt="nutribot"></img>
                         
                         <br></br>
                         Get fresh Water, Get some Nutrients, Feed'em 
                         <br></br> 
                         <div className="teaser2" >
                              The full Nutribot system, feeds whatever you want  
                                   
                         </div>

                         




                         <div className="wrapper">
                         
                        

                         <h1 className="teaser">Nutribot – The Smart Irrigation Computer for Perfect Results!</h1>

<p className="teaser">Say goodbye to manually mixing fertilizers and experience a new dimension of plant care with the <strong>Nutribot</strong>! The <strong>IoT Irrigation Computer</strong> is the innovative solution for precise and automated watering and fertilization – tailored to the unique needs of your plants.</p>

<h2>What makes the <strong>Nutribot</strong> special?</h2>
<ul>
    <li><p className="teaser"><strong >Automatic Fertilizer Composition</strong>: With just one button press, choose the perfect nutrient combination from up to 8 different fertilizer components – no more tedious manual mixing!</p></li>
    <li><p className="teaser"><strong >Perfect pH Levels</strong>: Integrate a <strong>pH-Down Component</strong> for automatic adjustment of pH levels, ensuring your plants always thrive in an optimal environment.</p></li>
    <li><p className="teaser"><strong >Customizable</strong>: The <strong>Nutribot</strong> can be flexibly tailored to your needs with customized software and hardware – whether you only want irrigation or a fully automated fertilization system.</p></li>
</ul>

<p className="teaser">With the <strong>Nutribot</strong>, you experience absolute precision and convenience. Enjoy perfect results every time, effortlessly – at the push of a button! Your plants will thank you.</p>

<h3><strong>Nutribot</strong> – The future of irrigation and fertilization, already in your garden today!</h3>



                          
<img src="/img/nutribot2.png" className="cover2" alt="nutribot"></img>
                        
                         </div>
                         
                    </div>
               </div>
               
          </div>
     )
}

export default Nutribot
